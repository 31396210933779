export const V1_PROJECT_IDS = {
  CONSTITUTION_DAO: 36,
  MOON_DAO: 199,
  SHARK_DAO: 7,
  PONZI_DAO: 140,
  PX_DAO: 11,
  SVSPOOL002: 27,
  FIDENZA420: 32,
  SANTA_DAO: 146,
  MICHAEL_JACKSON: 273,
  INFLATIONLESS_DAO: 180,
  VOTING_DAO: 290,
  DEFI_DAO: 31,
  JUICEBOX_DAO: 1,
}
